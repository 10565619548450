<template>
  <Browse
    :columns="[
      'product',
      'stock',
      'location',
      'stock_minimum',
      'stock_maximum',
      'reorder_point',
      'lead_days',
    ]"
    :formats="{
      stock: 'Decimal',
      stock_minimum: 'Decimal',
      stock_maximum: 'Decimal',
      reorder_point: 'Decimal',
      lead_days: 'Integer6',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="inventories"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
