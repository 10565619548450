var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'product',
    'stock',
    'location',
    'stock_minimum',
    'stock_maximum',
    'reorder_point',
    'lead_days' ],"formats":{
    stock: 'Decimal',
    stock_minimum: 'Decimal',
    stock_maximum: 'Decimal',
    reorder_point: 'Decimal',
    lead_days: 'Integer6',
  },"itemsPerPage":"10","searchOnStart":true,"table":"inventories"}})}
var staticRenderFns = []

export { render, staticRenderFns }